import React, { Component } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components'
import Newsletter from "../components/newsletter/newsletter";
import Layout from "../layouts";
import parse from 'html-react-parser'; 
import SEO from "../components/common/SEO";
import SocialMediaLink from "../components/common/SocialMediaLink"; 

const Wrapper = styled.div`
min-height: 100vh;
padding-top: 20px;
`;

class PostTemplate extends Component {

  render() {
    const post = this.props.data.wordpressPost;

    return (
      <Layout>
        <SEO post={post} />
        <section className="article">
          <Wrapper>
            <Container>
              <Row className="container news">
                <Col style={{ padding: 0 }}>
                  <label style={{ fontSize: "1.0em" }}>
                    { post.categories[0].name || "" } | {new Date(post.date).toISOString().slice(0, 10)}
                  </label>
                  <h2>{parse(post.title)} </h2>                                    
                </Col>
                
              </Row>
              <Row className="container news">
                <Col>
                  <SocialMediaLink text={post.title} url={post.path} />
                  <div style={{ height: "400px" }}>
                    {post.acf.image && (
                      <Img
                        loading="lazy"
                        style={{ marginBottom: "20px", height: "100%" }}                        
                        fluid={post.acf.image.localFile.childImageSharp.fluid}
                      />
                    )}
                  </div>
                  <div class='content-body'> 
                    {parse(post.content)}

                  </div>
                  </Col>
              </Row>
            </Container>
            <Container style={{ marginBottom: "50px" }}>
              <Row>
                <Col>
                  <Newsletter />
                </Col>
              </Row>
            </Container>
          </Wrapper>
        </section>
      </Layout>
    );
  }
}

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      content
      date
      slug
      title
      path
      categories {
        name
      }
      acf {
        seo_description
        image {
          id
          source_url
          title
          caption
          alt_text
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              },
              fixed {
                src
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        subtitle
      }
    }
  }
`
