import React, { Component } from "react";

class SocialMediaLink extends Component {
  render() {
    let text = this.props.text || ''; 
    let url = `https://www.factsarefirst.com/${this.props.url}` || 'https://www.factsarefirst.com'; 
    
    return (
      
      <div className="share-this" style={{marginBottom: "15px", marginTop: "-20px", position:"relative" }}>
        <span>Share This:</span>
        <a href={`https://twitter.com/share?text=${text}, visit: ${url}`} target="_blank" rel="noopener noreferrer">
          <i className="twitter icon icons white" />
        </a>
        <a href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} target="_blank" rel="noopener noreferrer">
          <i className="facebook icon icons white" />      
        </a>          
      </div>
    );
  }
}

export default SocialMediaLink;
